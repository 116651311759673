


.company {
    opacity: 0;
    transform: translateY(100px); 
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .company.active {
    opacity: 1;
    transform: translateY(0);
  }
  .about__content  hr {
    width: 120% ;
        height:70% ; 
        background-color: black; 


  }


.hr {
  width: 800px;
  border: 1px solid rgb(250, 9, 9); /* You can adjust the border properties as needed */
}

.p {
font-size: small;

}


