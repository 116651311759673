

.section__content h2{
    font-weight: 600;
    line-height: 55px;
}

.title {
    animation: typing 4s ;
    color: #00B1DA;
}

@keyframes typing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.section-info {
   margin: 90px;
    opacity: 0;
    transform: translateY(100px); 
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .section-info.active {
    opacity: 1;
    transform: translateY(0);
  }

  .image {
 height:20% ;  
    margin-top: -30%;
}

.centered {
    text-align: center;
    margin-right: -100%;
   margin-top: -88%;
    color: #f8f3f3;
    font-family: "Roboto", sans-serif;
    font-size: 65px;
    font-weight: 700;
    font-style: italic;
    display: block; 
  white-space: nowrap;
  overflow: hidden;
}



/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
/* font-family: 'Oswald', sans-serif; */

/* google icons */
@import url('https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');





* {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}

/* sections */
.slide {
display: block;
clear: both;
position: relative;
width: 90vw;
height: 100vh;
margin-top: 17%;
margin-bottom: -42%;
}



/* gallery style */
[data-am-gallery] {
position: relative;
width: 100%;
height: 100%;
}

[data-am-gallery] input[type="radio"] {
position: fixed;
top: -9999px;
}

[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .images .image:nth-child(5) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5) { border: solid 1px #926d34; }
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5):hover { opacity: 1; }

[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .images .image:nth-child(4) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4) { border: solid 1px #926d34; }
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4):hover { opacity: 1; }

[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .images .image:nth-child(3) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3) { border: solid 1px #926d34; }
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3):hover { opacity: 1; }

[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .images .image:nth-child(2) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2) { border: solid 1px #926d34; }
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2):hover { opacity: 1; }

[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .images .image:nth-child(1) { opacity: 1; }
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1) { border: solid 1px #926d34; }
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1):hover { opacity: 1; }

[data-am-gallery] .image {
position: absolute;
top: 0;
left: 0;
right: 0;
opacity: 0;
-webkit-transition: opacity .6s ease;
transition: opacity .6s ease;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
height: calc(100% - 10vh - 4px - 1rem);
}

[data-am-gallery] .navigation {
position: absolute;
width: 100vw;
bottom: 2px;
padding: .5rem;
display: flex;
justify-content: center;
background: #000;
}

/* img title */
.lb_img_description {
font-family: 'Oswald', sans-serif;
font-size: clamp(2rem, 3vw, 3vw);
font-weight: 100;
color: transparent;
background-image: linear-gradient( to right, #3a2510 0, #3a2510 100% );
-webkit-background-clip: text;
position: absolute;
bottom: 2rem;
left: 0;
right: 0;
margin: 0 auto;
display: table;
}

/* [data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .images .image:nth-child(1) .lb_img_description,
[data-am-gallery] input[type="radio"]:checked:nth-child(1) ~ .navigation .dot:nth-child(1) .lb_img_description,

[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .images .image:nth-child(2) .lb_img_description,
[data-am-gallery] input[type="radio"]:checked:nth-child(2) ~ .navigation .dot:nth-child(2) .lb_img_description,

[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .images .image:nth-child(3) .lb_img_description,
[data-am-gallery] input[type="radio"]:checked:nth-child(3) ~ .navigation .dot:nth-child(3) .lb_img_description,

[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .images .image:nth-child(4) .lb_img_description,
[data-am-gallery] input[type="radio"]:checked:nth-child(4) ~ .navigation .dot:nth-child(4) .lb_img_description,

[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .images .image:nth-child(5) .lb_img_description,
[data-am-gallery] input[type="radio"]:checked:nth-child(5) ~ .navigation .dot:nth-child(5) .lb_img_description {
-webkit-animation: fade-in 1s;
-moz-animation: fade-in 1s;
animation: fade-in 1s;
} */

@-webkit-keyframes fade-in {
0% { opacity: 0; transform: scale(0); }
100% { opacity: 1; transform: scale(1); }
}
@-moz-keyframes fade-in {
0% { opacity: 0; transform: scale(0); }
100% { opacity: 1; transform: scale(1); }
}
@keyframes fade-in {
0% { opacity: 0; transform: scale(0); }
100% { opacity: 1; transform: scale(1); }
}

/* nav dots */
[data-am-gallery] .dot {
display: table;
width: 10vw;
height: 10vh;
margin: 0;
cursor: pointer;
border: solid 1px #000;
}

[data-am-gallery] .dot:hover {
border: solid 1px #926d34;
}

/* nav buttons */
.button {
z-index: 9;
cursor: pointer;
}

.back {
position: absolute;
top: 50%;
left: 2rem;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
}

.forward {
position: absolute;
top: 50%;
right: 2rem;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
}

.material-symbols-outlined {
color: #fff;
font-size: clamp(3vw, 5vw, 5vw);
font-weight: 100;
}

.material-symbols-outlined:hover {
color: #926d34;
}

[data-am-gallery] .dot,
.material-symbols-outlined {
-webkit-transition: all .2s ease;
transition: all .2s ease;
}

/* text styles */
section h3 {
font-family: 'Oswald', sans-serif;
font-size: clamp(2rem, 3vw, 3vw);
font-weight: 100;
color: transparent;
background-image: linear-gradient( to right, #8f6a32 0, #cb9b51 22%, #f6e27a 45%, #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #8f6a32 100% );
-webkit-background-clip: text;
}

/* responsive design */
@media screen and (max-width: 680px) {
[data-am-gallery] .dot {
width: 20vw;
}
}

@media screen and (max-width: 380px) {
[data-am-gallery] .navigation {
display: none;
}

[data-am-gallery] .image {
height: 100vh;
background-position: center top;
}
}