

.header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background-color: #F0F7FD;
   
}

.navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.nav{

    display: flex;
    align-items: center;
    gap: 5;
}

.logo{
    display: flex;
    align-items: center;
    width: 20%;
}


.nav__list{
    display: flex;
    margin: 0;
    padding: 0%;
    list-style: none;
    column-gap: 1.2rem;
}

.nav__item a{
    text-decoration: none;
    font-weight:500 ;
    color: black;
    font-size: 1.2rem;
    transition: 0.3s;
}

.nav__item a:hover{
    color: #00B1DA;
}



@media only screen and (max-width:991px) {

    .nav__menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #989898;
        z-index: 99;
        display: none;
    }

    .nav__list{
        position: absolute;
        background: red;
        top: 0;
        right: 0;
        width: 250%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        padding-top: 80px;
    }

    
}

