
.company-row{
justify-content: space-between;
}

.company-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  
  
  .company-col h3 {
    margin: 0;
    padding: 1rem;
    display: flex; 
    border: 1.5px solid #989898;
    position: relative; 
    transition: border-color 0.3s, box-shadow 0.3s; 
}

.company-col h3:hover {
    border-color: transparent; 
    box-shadow: 0 0 10px rgba(0, 177, 218, 0.7); 
}
  
  .company-col a {
    text-decoration: none;
    color: black;
    display: flex;
  }

  


  .animate-charcter {
    text-transform: uppercase;
    animation: typing 4s ;
    color: black;
}

@keyframes typing {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

