

.ex{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.ex p{
    text-align: center;
}

.ex img{
    width: 30%;
}

.expertise a {
    text-decoration: none;
    font-weight:500 ;
    color: black;
    font-size: 1.2rem;
    transition: 0.3s;
}


.expertise h1{
    text-align: center;
    
}

.expertise h1 + hr {
    width: 50px;
        height: 4px; 
        background-color: black; 
        margin: 0 auto; 
  }

.text h4{
    padding: 30px;
}

.expertise-btn{
    background-color: #989898;
    
    text-decoration: none;
    padding: 0.8rem 1.8rem;
    border-radius: 30px; 
    font-weight: bold;
    transition: 0.4s;
    border: 2px solid transparent;
}

.expertise-btn:hover{
    background-color: transparent;
    border: 2px solid  #00B1DA;
    ;
}

.expertise-col{
    padding-top: 40px;
}

.expertise {
    opacity: 0;
    transform: translateY(100px); 
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .expertise.active {
    opacity: 1;
    transform: translateY(0);
  }

